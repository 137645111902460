<template>
  <a-form
    ref="formRef"
    novalidate
    :form="form"
    @submit.prevent="submitFilter"
  >
    <h3 class="mb-3">
      Filter
    </h3>
    <template v-if="!$route.query.business_id && getUserData.restriction_base !== 'Warehouse'">
      <label>{{ $t('order.business') }}</label>
      <a-form-item>
        <a-select
          v-decorator="['business', { initialValue: businessValue }]"
          class="w-100"
          mode="multiple"
          show-arrow
          :get-popup-container="() => $refs.formRef.$el"
          :not-found-content="$t('utils.not_found')"
          :placeholder="$t('utils.choose') + ' ' + $t('order.business')"
          :filter-option="filterOption"
        >
          <a-select-option v-for="bs in options_business" :key="bs.value">
            {{ bs.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </template>

    <template v-if="$route.path === '/sales'">
      <label>{{ $t('order.channel') }}</label>
      <a-form-item>
        <a-select
          v-decorator="['channel', { initialValue: channelValue }]"
          class="w-100"
          show-arrow
          mode="multiple"
          :get-popup-container="() => $refs.formRef.$el"
          :not-found-content="loadChannel ? undefined : null"
          :placeholder="$t('utils.choose') + ' ' + $t('order.channel')"
          :filter-option="filterOption"
          @focus="$emit('onFocusChannel')"
          @blur="$emit('onBlurChannel')"
        >
          <a-spin v-if="loadChannel" slot="notFoundContent" size="small" />
          <a-select-option v-for="channel in optionsChannel" :key="channel.value">
            {{ channel.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </template>
    <!-- !$route.query.warehouse_id && getUserData.restriction_base === 'Warehouse' -->
    <template v-if="!$route.query.warehouse_id || getUserData.restriction_base !== 'Warehouse'">
      <label>{{ $t('order.warehouse') }}</label>
      <a-form-item>
        <a-select
          v-decorator="['warehouse', { initialValue: warehouseValue }]"
          class="w-100"
          show-arrow
          mode="multiple"
          :get-popup-container="() => $refs.formRef.$el"
          :not-found-content="loadWarehouse ? undefined : $t('utils.not_found')"
          :placeholder="$t('utils.choose') + ' ' + $t('order.warehouse')"
          :filter-option="filterOption"
          @focus="$emit('onFocusWarehouse')"
          @blur="$emit('onBlurWarehouse')"
        >
          <a-spin v-if="loadWarehouse" slot="notFoundContent" size="small" />
          <a-select-option v-for="wh in options_warehouse" :key="wh.value">
            {{ wh.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </template>

    <div class="mt-3">
      <label>{{ $t('order.ordersPeriod') }}</label>
      <a-form-item>
        <a-range-picker
          v-decorator="[
            'date',
            {
              initialValue: orderPeriod,
              rules: [{ required: true, message: $t('order.require_order_period') }],
            },
          ]"
          class="w-100"
          :placeholder="[$t('utils.choose') + ' ' + $t('order.ordersPeriod'), '']"
          :allow-clear="false"
          :get-calendar-container="() => $refs.formRef.$el"
          :show-time="{ format: 'HH:mm:ss' }"
          format="YYYY-MM-DD HH:mm:ss"
          @change="onChangeDate"
        >
          <a-icon slot="suffixIcon" type="calendar" />
        </a-range-picker>
      </a-form-item>
    </div>

    <!-- <label>{{ $t('order.customer') }}</label>
    <a-form-item>
      <a-input
        v-decorator="['customer', { initialValue: customerValue }]"
        :placeholder="$t('order.customerName')"
      />
    </a-form-item>

    <div class="mt-3">
      <label>{{ $t('order.courier') }}</label>
      <a-form-item>
        <a-input
          v-decorator="['courier', { initialValue: courierValue }]"
          :placeholder="$t('order.courier_name')"
        />
      </a-form-item>
    </div> -->

    <div class="d-flex">
      <a-button @click="resetFilter">
        {{ $t('order.cleanFilter') }}
      </a-button>
      <a-button class="ml-auto" @click="cancelModal">
        {{ $t('utils.cancel') }}
      </a-button>
      <a-button type="primary" html-type="submit" class="ml-2">
        {{ $t('utils.apply') }}
      </a-button>
    </div>
  </a-form>
</template>

<script>
import isEqual from 'lodash/isEqual'
export default {
  props: [
    'businessValue',
    'orderPeriod',
    'loadWarehouse',
    'channelValue',
    'loadChannel',
    'optionsChannel',
    'warehouseValue',
    'customerValue',
    'courierValue',
  ],
  emits: ['onFocusChannel', 'onBlurChannel', 'onFocusWarehouse', 'onBlurWarehouse'],
  data() {
    return {
      form: this.$form.createForm(this),
      // loadBusiness: true,
      value: [],
      date: [],
      warehouse: [],
      business: [],
      search: '',
      customerFiltered: [],
      dateEdit: false,
    }
  },
  computed: {
    options_business() {
      // const options_business = (this.$store.state?.business?.businesses || []).map((item) => ({
      //   value: item.business_id,
      //   label: item.business_name,
      // }))
      return (this.$store.state?.user?.businessList || [])
        .filter(f => f.business_id !== 'all')
        .map(item => {
          return {
            value: item.business_id, // business_id | warehouse_id
            label: item.business_name || item.name,
          }
        })
    },
    // options_channel() {
    //   return (this.$store.state?.channel?.saleschannel || []).map((item) => ({
    //     value: item.id,
    //     label: item.name,
    //   }))
    // },
    options_warehouse() {
      return (this.$store.state?.warehouse?.warehouse || []).map(warehouse => ({
        value: warehouse.id,
        label: warehouse.name,
      }))
    },
    options_category() {
      return (this.$store.state?.products?.categories || []).map(categories => ({
        value: categories.id,
        label: categories.name,
      }))
    },
    getUserData() {
      return this.$store.state?.user || {}
    },
  },
  methods: {
    // blurBusiness() {
    //   if (!this.options_business.length && !this.loadBusiness) {
    //     this.loadBusiness = true
    //   }
    // },
    // blurChannel() {
    //   if (!this.options_channel.length && !this.loadChannel) {
    //     this.loadChannel = true
    //   }
    // },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    onChangeDate() {
      this.dateEdit = true
    },
    cancelModal() {
      this.$emit('close')
    },
    resetFilter() {
      this.dateEdit = false
      this.form.resetFields()
      this.$emit('reset')
    },
    submitFilter() {
      this.form.validateFields((err, value) => {
        if (!err) {
          const UND = undefined
          let orderIdBasedOnCustomerSelected = []

          this.customerFiltered.forEach(item => {
            orderIdBasedOnCustomerSelected = [
              ...orderIdBasedOnCustomerSelected,
              ...(item?.order_ids || []),
            ]
          })
          const { path, query } = this.$route
          let dateRange = {
            date_from: UND,
            date_until: UND,
          }
          if (this.dateEdit) {
            dateRange = {
              date_from: value?.date?.length ? this.$moment(value.date[0]).format() : UND,
              date_until: value?.date?.length ? this.$moment(value.date[1]).format() : UND,
            }
          }
          const parseQuery = {
            ...query,
            page: UND,
            business_id_sales: value?.business?.length ? value.business.join(',') : UND, // business_id
            // NOTES: set sales_channel_id from form value only page '/sales'
            sales_channel_id:
              path === '/sales'
                ? value?.channel?.length
                  ? value.channel.join(',')
                  : UND
                : query.sales_channel_id,
            warehouse_id: value?.warehouse?.length
              ? value.warehouse.join(',')
              : query.warehouse_id && this.getUserData.restriction_base === 'Warehouse'
              ? query.warehouse_id
              : UND,
            ...dateRange,
            customer_name: value.customer || UND,
            courier_name: value.courier || UND,
            order_id: orderIdBasedOnCustomerSelected.length
              ? orderIdBasedOnCustomerSelected.join(',')
              : UND,
            // sales_state: UND, // OPTION: Reset status to all
          }
          const isEdit = !isEqual(parseQuery, query)
          // console.log('submitFilter parseQuery: ', parseQuery) // Object.values(parseQuery).filter(Boolean).length
          if (isEdit) {
            this.$router.push({ query: parseQuery })
          }
          // Reset state date edit by user
          if (!value?.date?.length) {
            this.dateEdit = false
          }
          this.cancelModal()
        }
      })
    },
    // fetchBusiness() {
    //   if (this.loadBusiness) {
    //     const { business_id } = this.$route.query
    //     this.$store
    //       .dispatch('business/GETBUSINESSES', { business_id })
    //       .then(() => {
    //         // console.log('GETBUSINESSES res: ', res)
    //       })
    //       .catch((err) => {
    //         this.$message.error(err.message) // this.$t('utils.not_found')
    //         document.activeElement.blur()
    //       })
    //       .finally(() => { this.loadBusiness = false })
    //   }
    // },
  },
}
</script>
